import { ComponentStyleConfig } from "@chakra-ui/react";
import { NAVBAR_HEIGHT } from "src/slices/Header";
import { mode } from "@chakra-ui/theme-tools";

const Heading: ComponentStyleConfig = {
  baseStyle: (props) => ({
    fontFamily: "heading",
    color: mode("text-primary", "white")(props),
    lineHeight: "shorter",
    scrollMarginTop: NAVBAR_HEIGHT + 24,
    whiteSpace: "pre-wrap",
    fontWeight: "normal",
  }),

  sizes: {},

  variants: {
    "heading-1": {
      fontSize: {
        base: "font-42",
        lg: "font-58",
      },
      letterSpacing: "-0.02em",
      lineHeight: "shortest",
    },
    "heading-2": {
      fontSize: {
        base: "font-30",
        lg: "font-46",
      },
      lineHeight: "shorter",
      letterSpacing: "-0.02em",
    },
    "heading-3": {
      fontSize: {
        base: "font-24",
        lg: "font-35",
      },
      letterSpacing: "-0.02em",
    },
    "heading-4": {
      fontSize: {
        base: "font-20",
        lg: "font-28",
      },
      lineHeight: { base: "short", lg: "shorter"},
      letterSpacing: "-0.02em",
    },
    subtitle: {
      fontSize: { base: "font-18", lg: "font-24" },
      letterSpacing: "-0.02em",
      lineHeight: { base: "shorter", lg: "short" },
    },
    "subtitle-large": {
      fontSize: {
        base: "font-24",
        lg: "font-28",
      },
      lineHeight: { base: "short", lg: "shorter"},
      letterSpacing: "-0.02em",
    },
    "nav-title": {
      fontSize: { base: "font-15", md: "font-20" },
      letterSpacing: "-0.02em",
    },
  },

  defaultProps: {
    variant: "heading-1",
    size: "",
  },
};

export default Heading;
