export default {
  baseStyle: {
    borderRadius: "full",
    paddingY: "space-8",
    paddingX: "15px",
    fontWeight: "medium",
    lineHeight: "none",
    textTransform: "none"
  },

  sizes: {},

  variants: {
    "orange" : {
      bg: "brown-0",
      color: "white",
      fontWeight: "bold"
    },
    "blue" : {
      bg: "blue-0",
      color: "white",
      fontWeight: "bold"
    },
    "red": {
      bg: "red-0",
      color: "white",
      fontWeight: "bold"
    }
  },

  defaultProps: {
    variant: "orange",
  },
};
